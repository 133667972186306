var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.ready
        ? _c("div", [
            _c(
              "form",
              { staticClass: "form-horizontal", attrs: { id: "form-client" } },
              [
                _c("div", { staticClass: "row" }, [
                  _vm.$store.state.config.local_flavor.use_login
                    ? _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("Input", {
                            attrs: {
                              label: "Логин",
                              size: 6,
                              error: _vm.err.login
                            },
                            on: {
                              input: function($event) {
                                _vm.err.login = null
                              }
                            },
                            model: {
                              value: _vm.model.login,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "login", $$v)
                              },
                              expression: "model.login"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("Select", {
                        attrs: {
                          label: "Статус",
                          size: 7,
                          options: _vm.optionsYesNo,
                          value: "1",
                          error: _vm.err.disabled
                        },
                        on: {
                          input: function($event) {
                            _vm.err.disabled = null
                          }
                        },
                        model: {
                          value: _vm.model.disabled,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "disabled", $$v)
                          },
                          expression: "model.disabled"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c(
                        "Input",
                        {
                          attrs: {
                            label: "Пароль",
                            size: 6,
                            error: _vm.err.password
                          },
                          on: {
                            input: function($event) {
                              _vm.err.password = null
                            }
                          },
                          model: {
                            value: _vm.model.password,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "password", $$v)
                            },
                            expression: "model.password"
                          }
                        },
                        [
                          _c("Icon", {
                            attrs: {
                              name: "lock",
                              title: "Сгенерировать пароль"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.generatePassword.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$store.state.preload["/group"].length
                    ? _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("Select", {
                            attrs: {
                              label: "Группа",
                              size: 7,
                              options: _vm.groupOptions,
                              error: _vm.err.group
                            },
                            on: {
                              input: function($event) {
                                _vm.err.group = null
                              }
                            },
                            model: {
                              value: _vm.model.group,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "group", $$v)
                              },
                              expression: "model.group"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-9" },
                    [
                      _c("Input", {
                        attrs: {
                          label: "ФИО",
                          size: 7,
                          labelSize: 2,
                          error: _vm.err.fio
                        },
                        on: {
                          input: function($event) {
                            _vm.err.fio = null
                          }
                        },
                        model: {
                          value: _vm.model.fio,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "fio", $$v)
                          },
                          expression: "model.fio"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                !_vm.$store.state.config.local_flavor.hide_fop &&
                _vm.$store.state.preload["/fop"].length
                  ? [
                      _c("hr", {
                        staticStyle: {
                          "margin-top": "5px",
                          "margin-bottom": "10px"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-9" },
                          [
                            _c("Select", {
                              attrs: {
                                label: "Юрлицо",
                                options: _vm.fopOptions,
                                size: 7,
                                labelSize: 2,
                                error: _vm.err.fop
                              },
                              on: {
                                input: function($event) {
                                  _vm.err.fop = null
                                }
                              },
                              model: {
                                value: _vm.model.fop,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "fop", $$v)
                                },
                                expression: "model.fop"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _vm.$store.state.config.local_flavor.location_can_add_new
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _vm.$store.getters.permCanWrite("geo.data") ||
                        _vm.$store.getters.permCanWrite("geo.building")
                          ? [
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("LocationInput", {
                                    attrs: {
                                      size: 6,
                                      "label-size": 2,
                                      label: "Улица",
                                      error: _vm.err.street
                                    },
                                    on: {
                                      input: function($event) {
                                        _vm.err.street = null
                                      }
                                    },
                                    model: {
                                      value: _vm.model.street,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "street", $$v)
                                      },
                                      expression: "model.street"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("Input", {
                                    attrs: {
                                      size: 6,
                                      "label-size": 2,
                                      label: "Дом",
                                      error: _vm.err.house
                                    },
                                    on: {
                                      input: function($event) {
                                        _vm.err.house = null
                                      }
                                    },
                                    model: {
                                      value: _vm.model.house,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "house", $$v)
                                      },
                                      expression: "model.house"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("Input", {
                                    attrs: {
                                      size: 6,
                                      "label-size": 2,
                                      label: "Кв.",
                                      error: _vm.err.flat
                                    },
                                    on: {
                                      input: function($event) {
                                        _vm.err.flat = null
                                      }
                                    },
                                    model: {
                                      value: _vm.model.flat,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "flat", $$v)
                                      },
                                      expression: "model.flat"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          : [
                              _c("p", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "У вас нет прав на запись домов в базу географии (geo.building=rw)"
                                )
                              ])
                            ]
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$store.state.config.local_flavor.location_can_add_new
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-9" },
                        [
                          _c("LocationInput", {
                            attrs: {
                              size: 6,
                              "label-size": 2,
                              label: "Адрес",
                              error: _vm.err.full_location
                            },
                            on: {
                              input: function($event) {
                                _vm.err.full_location = null
                              }
                            },
                            model: {
                              value: _vm.model.full_location,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "full_location", $$v)
                              },
                              expression: "model.full_location"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("Input", {
                            attrs: {
                              size: 6,
                              "label-size": 2,
                              label: "Кв.",
                              error: _vm.err.flat
                            },
                            on: {
                              input: function($event) {
                                _vm.err.flat = null
                              }
                            },
                            model: {
                              value: _vm.model.flat,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "flat", $$v)
                              },
                              expression: "model.flat"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("Phone", {
                        attrs: {
                          size: 6,
                          label: "Телефон",
                          error: _vm.err.phone
                        },
                        on: {
                          input: function($event) {
                            _vm.err.phone = null
                          }
                        },
                        model: {
                          value: _vm.model.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "phone", $$v)
                          },
                          expression: "model.phone"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("Input", {
                        attrs: {
                          size: 9,
                          label: "Комментарий",
                          error: _vm.err.phone_comment
                        },
                        on: {
                          input: function($event) {
                            _vm.err.phone_comment = null
                          }
                        },
                        model: {
                          value: _vm.model.phone_comment,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "phone_comment", $$v)
                          },
                          expression: "model.phone_comment"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("Phone", {
                        attrs: {
                          size: 6,
                          label: "Телефон",
                          error: _vm.err.phone2
                        },
                        on: {
                          input: function($event) {
                            _vm.err.phone2 = null
                          }
                        },
                        model: {
                          value: _vm.model.phone2,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "phone2", $$v)
                          },
                          expression: "model.phone2"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("Input", {
                        attrs: {
                          size: 9,
                          label: "Комментарий",
                          error: _vm.err.phone2_comment
                        },
                        on: {
                          input: function($event) {
                            _vm.err.phone2_comment = null
                          }
                        },
                        model: {
                          value: _vm.model.phone2_comment,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "phone2_comment", $$v)
                          },
                          expression: "model.phone2_comment"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.$store.getters.permCanWrite("billing.client.personal")
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("Phone", {
                            attrs: {
                              name: "phone",
                              size: 6,
                              label: "Телефон",
                              error: _vm.err.phone3
                            },
                            on: {
                              input: function($event) {
                                _vm.err.phone3 = null
                              }
                            },
                            model: {
                              value: _vm.model.phone3,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "phone3", $$v)
                              },
                              expression: "model.phone3"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("Input", {
                            attrs: {
                              name: "phone",
                              size: 9,
                              label: "Комментарий",
                              error: _vm.err.phone3_comment
                            },
                            on: {
                              input: function($event) {
                                _vm.err.phone3_comment = null
                              }
                            },
                            model: {
                              value: _vm.model.phone3_comment,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "phone3_comment", $$v)
                              },
                              expression: "model.phone3_comment"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.$store.getters.permCanWrite("billing.client.personal")
                  ? _c("hr", {
                      staticStyle: {
                        "margin-top": "5px",
                        "margin-bottom": "10px"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.$store.getters.permCanWrite("billing.client.personal")
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("Select", {
                            attrs: {
                              label: "Соглашение",
                              options: _vm.optionsAgree,
                              value: "",
                              error: _vm.err.pers_data_agree
                            },
                            on: {
                              input: function($event) {
                                _vm.err.pers_data_agree = null
                              }
                            },
                            model: {
                              value: _vm.model.pers_data_agree,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "pers_data_agree", $$v)
                              },
                              expression: "model.pers_data_agree"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.$store.getters.permCanWrite(
                        "billing.client.personal"
                      ) && _vm.$store.state.config.local_flavor.show_birthday
                        ? _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("Date", {
                                attrs: {
                                  size: 6,
                                  label: "Дата рождения",
                                  error: _vm.err.birthday
                                },
                                on: {
                                  input: function($event) {
                                    _vm.err.birthday = null
                                  }
                                },
                                model: {
                                  value: _vm.model.birthday,
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, "birthday", $$v)
                                  },
                                  expression: "model.birthday"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.$store.getters.permCanWrite("billing.client.personal")
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("GenInput", {
                            attrs: {
                              size: 6,
                              "label-size": 3,
                              entity: "client",
                              attr: "doc_type",
                              error: _vm.err.doc_type,
                              apiTopic: "client"
                            },
                            model: {
                              value: _vm.model.doc_type,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "doc_type", $$v)
                              },
                              expression: "model.doc_type"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("Input", {
                            attrs: {
                              size: 6,
                              label: "Серия и номер",
                              error: _vm.err.passport_number
                            },
                            on: {
                              input: function($event) {
                                _vm.err.passport_number = null
                              }
                            },
                            model: {
                              value: _vm.model.passport_number,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "passport_number", $$v)
                              },
                              expression: "model.passport_number"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.$store.getters.permCanWrite("billing.client.personal")
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("Input", {
                            attrs: {
                              size: 9,
                              label: "Кем выдан",
                              error: _vm.err.passport_issued
                            },
                            on: {
                              input: function($event) {
                                _vm.err.passport_issued = null
                              }
                            },
                            model: {
                              value: _vm.model.passport_issued,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "passport_issued", $$v)
                              },
                              expression: "model.passport_issued"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("Date", {
                            attrs: {
                              size: 6,
                              label: "Когда выдан",
                              error: _vm.err.passport_date
                            },
                            on: {
                              input: function($event) {
                                _vm.err.passport_date = null
                              }
                            },
                            model: {
                              value: _vm.model.passport_date,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "passport_date", $$v)
                              },
                              expression: "model.passport_date"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("hr", {
                  staticStyle: { "margin-top": "5px", "margin-bottom": "10px" }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-9" },
                    [
                      _c("TextArea", {
                        attrs: {
                          labelSize: 2,
                          size: 9,
                          label: "Комментарий",
                          error: _vm.err.comments
                        },
                        on: {
                          input: function($event) {
                            _vm.err.comments = null
                          }
                        },
                        model: {
                          value: _vm.model.comments,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "comments", $$v)
                          },
                          expression: "model.comments"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-9" },
                    [
                      _c("Select", {
                        attrs: {
                          size: 10,
                          labelSize: 2,
                          label: "Тип подключения",
                          options: _vm.optionsConnection,
                          error: _vm.err.connect_type
                        },
                        on: {
                          input: function($event) {
                            _vm.err.connect_type = null
                          }
                        },
                        model: {
                          value: _vm.model.connect_type,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "connect_type", $$v)
                          },
                          expression: "model.connect_type"
                        }
                      })
                    ],
                    1
                  )
                ])
              ],
              2
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c(
                    "Flipper",
                    { attrs: { title: "Дополнительные свойства" } },
                    [
                      _c(
                        "form",
                        {
                          staticClass: "form-horizontal",
                          attrs: { id: "form-tags", onsubmit: "return(false)" }
                        },
                        _vm._l(
                          Math.round(
                            _vm.$store.state.preload["/client-tags"].length / 2
                          ),
                          function(i) {
                            return _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("ClientTagInput", {
                                    attrs: {
                                      tag: _vm.getOddTag(i),
                                      error: _vm.err[_vm.getOddTag(i).name]
                                    },
                                    model: {
                                      value: _vm.tags[_vm.getOddTag(i).name],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.tags,
                                          _vm.getOddTag(i).name,
                                          $$v
                                        )
                                      },
                                      expression: "tags[getOddTag(i).name]"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.$store.state.preload["/client-tags"][i]
                                ? _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("ClientTagInput", {
                                        attrs: {
                                          tag: _vm.getEvenTag(i),
                                          error: _vm.err[_vm.getEvenTag(i).name]
                                        },
                                        model: {
                                          value:
                                            _vm.tags[_vm.getEvenTag(i).name],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tags,
                                              _vm.getEvenTag(i).name,
                                              $$v
                                            )
                                          },
                                          expression: "tags[getEvenTag(i).name]"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          }
                        ),
                        0
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-5 col-md-offset-5" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    attrs: { type: "button" },
                    on: { click: _vm.submit }
                  },
                  [
                    _c("Icon", { attrs: { name: "plus" } }),
                    _vm._v(" Добавить")
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn pull-right",
                    attrs: { type: "reset" },
                    on: { click: _vm.clearModel }
                  },
                  [_vm._v("Очистить форму")]
                )
              ])
            ])
          ])
        : _vm._l(9, function(i) {
            return _c("SkelInput", { key: i })
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }