<template>
<div>
  <div v-if="ready" >
  <form class="form-horizontal" id="form-client" >
    <div class="row">
        <div class="col-md-6" v-if="$store.state.config.local_flavor.use_login">
          <Input label="Логин" :size=6 v-model="model.login" :error=err.login @input="err.login=null" />
        </div>
    
        <div class="col-md-6">
          <Select label="Статус" :size=7 :options=optionsYesNo value=1 :error=err.disabled v-model="model.disabled" @input="err.disabled=null"  />
        </div>  
    </div>

    <div class="row">
        <div class="col-md-6">
          <Input label="Пароль" :size=6 v-model="model.password" :error=err.password @input="err.password=null" >
            <Icon name=lock title="Сгенерировать пароль" @click.native="generatePassword" />
          </Input>
        </div>

        <div class="col-md-6" v-if="$store.state.preload['/group'].length">
          <Select label="Группа" v-model="model.group" :size=7 :options=groupOptions :error=err.group @input="err.group=null" />
        </div>
    </div>

    <div class="row">
        <div class="col-md-9">
            <Input label="ФИО" :size=7 :labelSize=2 v-model="model.fio" :error=err.fio @input="err.fio=null" />
        </div>
    </div>

    <template v-if="!$store.state.config.local_flavor.hide_fop && $store.state.preload['/fop'].length">
      <hr style='margin-top: 5px; margin-bottom: 10px;'>
      <div class="row">
        <div class="col-md-9">
          <Select label="Юрлицо" :options=fopOptions v-model="model.fop" :size=7 :labelSize=2 :error=err.fop @input="err.fop=null" />
        </div>
      </div>
    </template>

    <hr/>

    <div class="row" v-if='$store.state.config.local_flavor.location_can_add_new'>
      <template v-if='$store.getters.permCanWrite("geo.data") || $store.getters.permCanWrite("geo.building")'>
        <div class="col-md-6">
          <LocationInput :size=6 :label-size=2 label="Улица" v-model="model.street" :error=err.street @input="err.street=null" /> 
        </div>
        <div class="col-md-3">
          <Input :size=6 :label-size=2 label="Дом" v-model="model.house" :error=err.house @input="err.house=null" /> 
        </div>
        <div class="col-md-3">
          <Input :size=6 :label-size=2 label="Кв." v-model="model.flat" :error=err.flat @input="err.flat=null" /> 
        </div>
      </template>
      <template v-else>
        <p class="text-danger">У вас нет прав на запись домов в базу географии (geo.building=rw)</p>
      </template>
    </div>

    <div class="row" v-if='!$store.state.config.local_flavor.location_can_add_new'>
        <div class="col-md-9">
          <LocationInput :size=6 :label-size=2 label="Адрес" v-model="model.full_location" :error=err.full_location @input="err.full_location=null" /> 
        </div>
        <div class="col-md-3">
          <Input :size=6 :label-size=2 label="Кв." v-model="model.flat" :error=err.flat @input="err.flat=null" /> 
        </div>
    </div>
    
    <hr/>

    <div class="row">
        <div class="col-md-6">
          <Phone :size=6 label="Телефон" v-model="model.phone" :error=err.phone @input="err.phone=null" />
        </div>
        <div class="col-md-6">
          <Input :size=9 label="Комментарий" v-model="model.phone_comment" :error=err.phone_comment @input="err.phone_comment=null" />
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
          <Phone :size=6 label="Телефон" v-model="model.phone2" :error=err.phone2 @input="err.phone2=null"/>
        </div>
        <div class="col-md-6">
          <Input :size=9 label="Комментарий" v-model="model.phone2_comment" :error=err.phone2_comment @input="err.phone2_comment=null" />
        </div>
    </div>

    <div class="row" v-if='$store.getters.permCanWrite("billing.client.personal")'>
        <div class="col-md-6">
          <Phone name=phone :size=6 label="Телефон" v-model="model.phone3" :error=err.phone3 @input="err.phone3=null" />
        </div>
        <div class="col-md-6">
          <Input name=phone :size=9 label="Комментарий" v-model="model.phone3_comment" :error=err.phone3_comment @input="err.phone3_comment=null" />
        </div>
    </div>

    <hr style='margin-top:5px;margin-bottom:10px;' v-if='$store.getters.permCanWrite("billing.client.personal")'>

    <div class="row" v-if='$store.getters.permCanWrite("billing.client.personal")'>
        <div class="col-md-6">
          <Select label="Соглашение" v-model="model.pers_data_agree" :options=optionsAgree value="" :error=err.pers_data_agree @input="err.pers_data_agree=null" />
        </div>
        <div class="col-md-6"" v-if='$store.getters.permCanWrite("billing.client.personal") && $store.state.config.local_flavor.show_birthday'>
          <Date :size=6 label="Дата рождения" v-model="model.birthday" :error=err.birthday @input="err.birthday=null" />
        </div>
    </div>

    <div class="row" v-if='$store.getters.permCanWrite("billing.client.personal")'>
        <div class="col-md-6">
          <GenInput :size=6 :label-size=3 entity="client" attr="doc_type" v-model="model.doc_type" :error=err.doc_type apiTopic="client" />
        </div>
        <div class="col-md-6">
          <Input :size=6 label="Серия и номер" v-model="model.passport_number" :error=err.passport_number @input="err.passport_number=null" />
        </div>
    </div>

    <div class="row" v-if='$store.getters.permCanWrite("billing.client.personal")'>
        <div class="col-md-6">
          <Input :size=9 label="Кем выдан" v-model="model.passport_issued" :error=err.passport_issued @input="err.passport_issued=null" />
        </div>
        <div class="col-md-6">
          <Date :size=6 label="Когда выдан" v-model="model.passport_date" :error=err.passport_date @input="err.passport_date=null" />
        </div>
    </div>


    <hr style='margin-top: 5px; margin-bottom: 10px;'>

    <div class="row">
        <div class="col-md-9">
          <TextArea :labelSize=2 :size=9 label="Комментарий" v-model=model.comments :error=err.comments @input="err.comments=null" />
        </div>
    </div>
    
    <hr>

    <div class="row">
        <div class="col-md-9">
          <Select :size=10 :labelSize=2 label="Тип подключения" v-model="model.connect_type" :options=optionsConnection 
          :error=err.connect_type @input="err.connect_type=null" />
        </div>
    </div>
  </form>

<!-- Дополнительные теги  -->
  <hr>

  <div class="row">
    <div class="col-md-12">
      <Flipper title="Дополнительные свойства" >
        <form class="form-horizontal" id="form-tags" onsubmit="return(false)">
        
            <div class="row" v-for="i in Math.round($store.state.preload['/client-tags'].length/2)" >
                <div class="col-md-6">
                  <ClientTagInput :tag=getOddTag(i) v-model="tags[getOddTag(i).name]" :error="err[getOddTag(i).name]"  />
                </div>
                <div class="col-md-6" v-if="$store.state.preload['/client-tags'][i]">
                   <ClientTagInput :tag=getEvenTag(i) v-model="tags[getEvenTag(i).name]" :error="err[getEvenTag(i).name]" />
                </div>
            </div>
          
        </form>
      </Flipper>
    
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-md-5 col-md-offset-5">
        <button class="btn" type="button" @click=submit ><Icon name=plus /> Добавить</button>
        <button class="btn pull-right" type="reset" @click=clearModel >Очистить форму</button>
    </div>
  </div>
</div>

<template v-else>
<SkelInput v-for="i in 9" :key=i />
</template>

</div>

</template>

<script>

import {genRandomNonAmbLc, okPromise} from "common"
import darsan from "darsan"
import {goto} from "navigation"
import {notifyError} from "common"
import moment from "moment"

import Input from "common/visual/input/Input.vue"
import Phone from "common/visual/input/Phone.vue"
import Select from "common/visual/input/Select.vue"
import TextArea from "common/visual/input/TextArea.vue"
import Date from "common/visual/input/Date.vue"
import Flipper from "common/visual/Flipper.vue"
import SkelInput from "common/visual/SkelInput.vue"
import GenInput from "common/visual/input/GenInput.vue"

import ClientTagInput from "client/new/ClientTagInput.vue"
import LocationInput from "common/visual/input/LocationInput.vue"

export default {
  name: "ClientNew",
  components: {Input, Select, SkelInput, TextArea, Date, Flipper, ClientTagInput, LocationInput, Phone, GenInput},
  
  data()
  {
    return {
      ready: null,
      model: {
      },
      tags: {},
      err: {},
    }
  },
  
  created()
  {
    this.ensure(["fop", "group", "client-tags"])
    .then( () => 
    {
      this.clearModel() 
      this.ready = true
    })
  },
  
  methods: {
    generatePassword()
    {
      this.$set(this.model, "password", genRandomNonAmbLc(8).toLowerCase())
    },
    
    validate()
    {
      const error = {}

      if ( this.$store.state.config.local_flavor.use_login && !this.model.login) 
      {
        error.login = "Введите логин нового пользователя"
      }

      if ( this.$store.state.config.local_flavor.use_login && this.model.login && this.model.login.search(/^[\w\_-]+$/) === -1) 
      {
        error.login = "Логин может состоять только из латинских букв, цифр, тире и подчеркивания"
      }

      if (!this.model.password) 
      {
        error.password = "Не введён пароль"
      }

      if (!this.model.fio) 
      {
        error.fio = "Пропущена фамилия пользователя"
      }

      if (this.$store.state.config.local_flavor.location_can_add_new && !this.model.house) 
      {
        error.house = "Пропущен номер дома"
      }

      if (this.$store.state.config.local_flavor.location_can_add_new && !this.model.street) 
      {
        error.street = "Не выбрана улица"
      }

      if (this.model.group==undefined) 
      {
        error.group = "Не выбрана группа"
      }

      for (name of ['phone','phone2','phone3'])
      {
        let phone = this.model[name]
        if (!phone) continue
        
        if (!phone.match(/\d{10}/))
        {
          error[name] = "Неправильный формат номера"
        }
      }
      
      if (this.$store.state.config.local_flavor.show_birthday && this.model.birthday) 
      {
        const date = moment(this.model.birthday)
        if (date.year() < 1930 || date.year() > moment().year()-18)
        {
          error.birthday = "Год рождения сомнителен"
        }
      }

      if (this.$store.state.config.local_flavor.show_birthday && this.model.email) 
      {
        if (!this.model.email.match(/^([a-zA-Z][\w\_\.]{2,40})\@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,4})$/))
        {
          error.email = "Неправильный адрес электронной почты"
        }
      }

      return error
    },
    
    clearModel()
    {
      for (name of Object.keys(this.model))
      {
        this.model[name] = ""
      }

      for (const el of this.$store.state.clientTagList)
      {
        this.$set(this.tags, el.name, el.default_value)
      }
    },
    
    submit() {
      const error = this.validate()
      
      if (Object.keys(error).length) {
        this.err = error
        return
      }

      const data = Object.fromEntries(Object.entries(this.model).filter(([key,el]) => el !== null && el !== undefined))
      const dataTags = Object.fromEntries(Object.entries(this.tags).filter(([key,el]) => el !== ''))
      
      const getHouseLocation = this.$store.state.config.local_flavor.location_can_add_new ? 
        darsan.postm("", "geo", "/street/"+this.model.street.replace(/\|.*$/,""), {house: this.model.house}).then(reply =>
        {
          data.full_location = reply.entity + "|" + reply.fullname;
          delete data.street;
          delete data.house;
        })
        : 
        okPromise()

      getHouseLocation
        .then( () => darsan.postm("", "client", "/client", data))
        .then(resp => 
        {
          const id = resp.entity

          if (Object.keys(dataTags).length) 
          {
            darsan.patch("", "client", "/client/" + id + "/tag", dataTags)
          }
          
          if (this.model.connect_type === 'pppoe') 
          {
            goto("/client/" + id + "/ppp")
          } 
          else if (this.model.connect_type === 'ipoe') 
          {
            goto("/client/" + id + "/ipoe")
          } 
          else if (this.model.connect_type) 
          {
            goto("/client/" + id + "/" + this.model.connect_type )
          } 
          else 
          {
            goto("/client/" + id)
          }
          
          this.clearModel()
        })
        .fail(xhr => 
        {
          const [name, text] = darsan.namedError(xhr)

          if (name)
          {
            this.$set(this.err, name, text)
          }
        });

    },
    
    getOddTag(i)
    {
      return this.$store.state.preload['/client-tags'][(i-1)*2] || {}
    },
    getEvenTag(i)
    {
      return this.$store.state.preload['/client-tags'][(i-1)*2+1] || {}
    }
  },
  
  computed: {
    optionsYesNo()
    {
      return [
        {value: 1, text: "Отключен"},
        {value: "", text: "Активен"},
      ]
    },

    optionsAgree()
    {
      return [
        {value: "", text: "Не подписано"},
        {value: 1, text: "Подписано"},
      ]
    },

    optionsConnection()
    {
      return [
        {value: "", text: ""},
        {value: "ppp", text: "PPPoE"},
        {value: "ipoe", text: "IPoE"},
      ]
    },
    
    groupOptions()
    {
      const list = this.$store.state.preload['/group'].map(el => { return {text: el.entity+": "+el.display_name, value: el.entity} })
      list.unshift({text: "", value: undefined})
      return list
    },

    fopOptions()
    {
      return this.$store.state.preload['/fop'].map(el => { return {text: el.display_name, value: el.entity} })
    },
  },
}
</script>
